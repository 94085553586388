import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyers-tier-pie-chart',
  templateUrl: './buyers-tier-pie-chart.component.html',
})
export class BuyersTierPieChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
