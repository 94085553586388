import 'core-js/shim';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HelperService, ConstantService, ApiService } from '@app/core';

am4core.useTheme(am4themes_animated);
import * as moment from 'moment';

@Component({
  selector: 'app-podium-stats',
  templateUrl: './podium-stats.component.html',
  styles: []
})
export class PodiumStatsComponent implements OnInit, OnDestroy {
  private chart: am4charts.PieChart;
  public chartData: any = [];
  public currentMonth: string;
  public currentYear: string;
  years: any = [];
  months: any = [];

  filterOptions = {
    filter: 'feedback_score',
    month: '',
    year: ''
  };

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();

    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();

    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;

    this.initChart();
    this.loadData();
  }

  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETPODIUMSTATS, '?filter=' + this.filterOptions.filter + '&month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      res => {
        if (res.Status === 200) {
          this.chartData = res.Data;
          this.initChart();
        } else {
          this.chartData = [];
          this.initChart();
        }
      }, err => console.log('error in getting user tiers')
    );
  }

  initChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('podiumStatsChart', am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = this.chartData;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 300;
      categoryAxis.tooltip.disabled = true;
      // categoryAxis.renderer.minHeight = 100;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 10;
      valueAxis.renderer.minGridDistance = 40;
      valueAxis.min = 0;

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'columnName';
      series.dataFields.categoryX = 'name';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // Cursor
      chart.cursor = new am4charts.XYCursor();

    });
  }

  applyFilter() {
    this.loadData();
  }


  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
