import 'core-js/shim';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ConstantService, ApiService, HelperService } from '@app/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-buyers-sales-pie-chart',
  templateUrl: './buyers-sales-pie-chart.component.html',
})
export class BuyersSalesPieChartComponent implements OnInit, OnDestroy {
  private chart: am4charts.PieChart;
  chartData: any = [];
  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  filterOptions = {
    month: '',
    year: ''
  };
  user_idms_id: any = 0;
  userRoleId: any = 0;
  totalSales = 0;
  totalMySales = 0;

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    const sessionStorageiDMSID = this.helperSvc.ssGetItem(this.constantSvc.dashboardConfig.DASHBOARD_USER_iDMSID);
    const sessionStorageRoleID = this.helperSvc.ssGetItem(this.constantSvc.dashboardConfig.DASHBOARD_USER_ROLE);
    const currentUser = this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER);
    const currentUserIdmsId = currentUser.userData.idms_id;
    const currentUserRole = currentUser.userData.role_id;

    if (sessionStorageiDMSID) {
      this.user_idms_id = sessionStorageiDMSID;
      this.userRoleId = sessionStorageRoleID;
    } else {
      this.user_idms_id = currentUserIdmsId;
      this.userRoleId = currentUserRole;
    }

    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;

    if (this.userRoleId === '1') {
      this.initChart();
      this.loadSales();
    } else {
      this.individualPieChart();
      this.countAllSales();
      this.countSalesByUser();
    }
  }

  loadSales() {
    this.apiSvc.getService(this.constantSvc.APIConfig.LOADSALESBYBUYERS, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        this.chartData = data.Data;
        this.initChart();
      }, error => console.log('error in load Sales by buyers function ', error)
    );
  }

  countAllSales(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.BUYERCOUNTSALES, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        if (data.Status === 200) {
          this.totalSales = data.Data.total;
          this.individualPieChart();
        }
      },
    );
  }

  countSalesByUser(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.BUYERCOUNTSALES, '?buyerId=' + this.user_idms_id + '&month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        if (data.Status === 200) {
          this.totalMySales = data.Data.total;
          this.individualPieChart();
        }
      },
    );
  }

  applyFilter() {
    if (this.userRoleId === '1') {
      this.loadSales();
    } else {
      this.countAllSales();
      this.countSalesByUser();
    }

  }

  initChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('buyersSalesPieChart', am4charts.PieChart);
      chart.responsive.enabled = true;
      chart.marginTop = 25;
      chart.radius = 100;
      chart.data = this.chartData;

      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'sales';
      pieSeries.dataFields.category = 'name';
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.tooltipText = '{name}: {value.percent.formatNumber("#.0")}% ({value.value}), Avg Profit: ${avgProfit}';
      pieSeries.labels.template.text = '{name}';
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

    });
  }

  individualPieChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('buyersSalesPieChart', am4charts.PieChart);
      chart.responsive.enabled = true;
      chart.marginTop = 25;
      chart.radius = 100;
      // Add data
      chart.data = [{
        'name': 'My Sales',
        'sales': this.totalMySales
      }, {
        'name': 'Team Sales',
        'sales': this.totalSales - this.totalMySales
      }];

      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'sales';
      pieSeries.dataFields.category = 'name';
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.labels.template.text = '{name}';
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
