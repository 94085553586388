import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPercent'
})
export class PercentPipe implements PipeTransform {

  transform(number: number, fullValue: number): any {
    return number / fullValue * 100;
  }

}
