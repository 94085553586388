import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/core';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html'
})
export class AlertComponent implements OnInit {
  message: any = {};
  loader: any = false;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.getMessage().subscribe(message => this.message = message);
  }

  removeAlert() {
    this.message = {};
  }

}
