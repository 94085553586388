import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

  // the role the user must have
  @Input() appHasRole: string;
  stop$ = new Subject();
  isVisible = false;

  userRole = '';

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authenticationService: AuthService
  ) { }

  ngOnInit() {

    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser && currentUser.token) {
      const roleId = currentUser.userData.role_id;

      if (roleId === '1') {
        this.userRole = 'admin';
      } else if (roleId === '2' || roleId === '3') {
        this.userRole = 'manager';
      } else if (roleId === '4' || roleId === '5') {
        this.userRole = 'sales-person';
      } else if (roleId === '6') {
        this.userRole = 'buyer';
      } else if (roleId === '7') {
        this.userRole = 'inspector';
      }

      if (this.appHasRole === this.userRole) {
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

}
