import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, Observer, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { HelperService } from "./helper.service";
import { ConstantService } from "./constant.service";
import { AlertService } from "./alert.service";
import { AuthService } from "./auth.service";
declare var jQuery: any;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private helperSvc: HelperService,
    private constantSvc: ConstantService,
    private authenticationSvc: AuthService,
    private _cookieService: CookieService,
    private alertService: AlertService
  ) {}

  uploadFile(url, formData) {
    return this.http
      .post<any>(this.constantSvc.APIBaseURL + url, formData, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((event) => this.getEventMessage(event, formData)),
        catchError(this.handleError)
      );
  }

  public postEmailAttachment(url, data): Observable<any> {
    return this.http
      .post<any>(this.constantSvc.APIBaseURL + url, data, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        // map(event => this.getEventMessage(event, data)),
        catchError(this.handleError)
      );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return event.body;

      default:
        return `File "${
          formData.get("profile").name
        }" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: "progress", message: percentDone };
  }

  postService(url, data): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.helperSvc.loaderStart();
      this.http
        .post(this.constantSvc.APIBaseURL + url, data, this.jwt())
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
            this.helperSvc.loaderStop();
          },
          (err) => {
            this.helperSvc.errorHandler(err);
            observer.complete();
            this.helperSvc.loaderStop();
          }
        );
    });
  }

  getService(url: any, params: any): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.helperSvc.loaderStart();
      this.http
        .get(this.constantSvc.APIBaseURL + url + params, this.jwt())
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
            this.helperSvc.loaderStop();
          },
          (err) => {
            this.helperSvc.errorHandler(err);
            observer.complete();
            this.helperSvc.loaderStop();
          }
        );
    });
  }

  putService(url: any, data: any): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.helperSvc.loaderStart();
      this.http
        .put(this.constantSvc.APIBaseURL + url, data, this.jwt())
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
            this.helperSvc.loaderStop();
          },
          (err) => {
            this.helperSvc.errorHandler(err);
            observer.complete();
            this.helperSvc.loaderStop();
          }
        );
    });
  }

  deleteService(url, params): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.helperSvc.loaderStart();
      this.http
        .delete(this.constantSvc.APIBaseURL + url + params, this.jwt())
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
            this.helperSvc.loaderStop();
          },
          (err) => {
            this.helperSvc.errorHandler(err);
            observer.complete();
            this.helperSvc.loaderStop();
          }
        );
    });
  }

  getRequests(url, params): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.http.get(this.constantSvc.APIBaseURL + url + params).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
          this.helperSvc.loaderStop();
        },
        (err) => {
          this.helperSvc.errorHandler(err);
          observer.complete();
        }
      );
    });
  }

  getNotifications(url, params): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.http.get(this.constantSvc.APIBaseURL + url + params).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
          this.helperSvc.loaderStop();
        },
        (err) => {
          this.helperSvc.errorHandler(err);
          observer.complete();
        }
      );
    });
  }

  getRequestUpdateNotification(url, params): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.http.get(this.constantSvc.APIBaseURL + url + params).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
          this.helperSvc.loaderStop();
        },
        (err) => {
          this.helperSvc.errorHandler(err);
          observer.complete();
        }
      );
    });
  }

  isExists(url, data): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      this.http
        .post(this.constantSvc.APIBaseURL + url, data, this.jwt())
        .subscribe(
          (res) => {
            observer.next(res);
            observer.complete();
          },
          (err) => {
            this.helperSvc.errorHandler(err);
            observer.complete();
          }
        );
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened. Please try again later.");
  }

  // get all sales list
  public findSales(
    filter = "",
    sort = "ContractDate",
    sortOrder = "desc",
    pageNumber = 0,
    pageSize = 10,
    userId = "",
    role = "",
    salesPerson = "",
    financePerson = "",
    dealId = "",
    stock = "",
    customerState = "",
    salesPrice = "",
    vAutoPrice = "",
    mrFee = "",
    eligibleToPay = "",
    flag = "",
    lender = "",
    commissionPaid = "",
    backendCommissionPaid = "",
    isIndividual = false,
    acctType = "",
    fromDate = "",
    toDate = "",
    sortByLenderDesc = ""
  ): Observable<any[]> {
    return this.http
      .get(this.constantSvc.APIBaseURL + this.constantSvc.APIConfig.SALES_NEW, {
        params: new HttpParams()
          .set("filter", filter)
          .set("sort", sort)
          .set("sortOrder", sortOrder)
          .set("pageNumber", pageNumber.toString())
          .set("pageSize", pageSize.toString())
          .set("userId", userId)
          .set("role", role)
          .set("salesPerson", salesPerson)
          .set("financePerson", financePerson)
          .set("dealId", dealId)
          .set("stock", stock)
          .set("customerState", customerState)
          .set("salesPrice", salesPrice)
          .set("vAutoPrice", vAutoPrice)
          .set("mrFee", mrFee)
          .set("eligibleToPay", eligibleToPay)
          .set("flag", flag)
          .set("lender", lender)
          .set("commissionPaid", commissionPaid)
          .set("backendCommissionPaid", backendCommissionPaid)
          .set("isIndividualSales", isIndividual.toString())
          .set("acctType", acctType)
          .set("fromDate", fromDate)
          .set("toDate", toDate)
          .set("sortByLenderDesc", sortByLenderDesc),
      })
      .pipe(map((res) => res["Data"]));
  }

  public findInspectionReport(
    filter = "",
    sort = "created_at",
    sortOrder = "DESC",
    pageNumber = 0,
    pageSize = 10,
    inspector = "",
    stock = "",
    vin = "",
    eligible = "",
    commissionPaid = "",
    inspection = "",
    isIndividual = false,
    userName = ""
  ): Observable<any[]> {
    return this.http
      .get(
        this.constantSvc.APIBaseURL +
          this.constantSvc.APIConfig.GETINSPECTIONREPORT,
        {
          params: new HttpParams()
            .set("filter", filter)
            .set("sort", sort)
            .set("sortOrder", sortOrder)
            .set("pageNumber", pageNumber.toString())
            .set("pageSize", pageSize.toString())
            .set("inspector", inspector)
            .set("stock", stock)
            .set("vin", vin)
            .set("eligible", eligible.toString())
            .set("commissionPaid", commissionPaid.toString())
            .set("inspection", inspection)
            .set("isIndividual", isIndividual.toString())
            .set("userName", userName),
        }
      )
      .pipe(
        map((res) => {
          return res["Data"];
        })
      );
  }

  private jwt() {
    const token = this._cookieService.get("UserToken");
    const currentUser = this.authenticationSvc.currentUserValue;
    if (!token) {
      this.alertService.error("Session expire, please login again.");
      this.authenticationSvc.logout();
      jQuery(".modal").modal("hide");
      return;
    } else {
      this._cookieService.delete("UserToken");
      this._cookieService.set(
        "UserToken",
        currentUser.token,
        new Date(Date.now() + 30 * 60 * 1000)
      ); // cookie set for 30 minutes

      const headers = {
        headers: new HttpHeaders({
          Authorization: `${currentUser.token}`,
          "Content-Type": "application/json",
        }),
      };
      return headers;
    }
  }
}
