import { Router } from '@angular/router';
import { ConstantService } from './../../../core/services/constant.service';
import { HelperService } from './../../../core/services/helper.service';
import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { ApiService } from './../../../core/services/api.service';
import { AlertService } from '@app/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
declare var $: any;
import * as moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-cash-advance',
  templateUrl: './cash-advance.component.html',
  styleUrls: ['./cash-advance.component.scss']
})
export class CashAdvanceComponent implements OnInit, OnDestroy {
  private dataUrl = 'sales/cashAdvanceAutomation';
  public modalRef: BsModalRef;
  public modalRef1: BsModalRef;
  public config: { animated: true };
  public oTable: any;
  public totalRecords = 0;
  public fromDate = '';
  public toDate = '';
  public listData = [];

  public userId = '';
  public roleId = '';
  public isIndividualSales = false;
  public pageUrl = '/sales/list';

  // for sales person table
  salesPersonName = '';
  totalSalesCount = '';

  selected: { startDate: moment.Moment, endDate: moment.Moment };

  newFieldsForm: FormGroup;
  fields: Array<any> = [];
  currentId: any;
  totalCommission: any;
  totalAmount: any;
  isAdmin: boolean = false;
  formData = new FormData();

  // salesPersonName: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private modalService: BsModalService,
    private helperService: HelperService,
    private constantService: ConstantService,
    private alertSvc: AlertService,

  ) { }

  ngOnInit() {
    const currentUser = this.helperService.lsGetItem(this.constantService.tokenConfig.CURRENT_USER);
    if (currentUser) {
      const roleId = currentUser.userData.role_id;

      if (roleId === '4' || roleId === '5') {
        this.pageUrl = '/deals/list';
        this.isIndividualSales = true;
        this.userId = currentUser.userData.idms_id;
        this.roleId = roleId;
      }
      if (roleId === '1') {
        this.isAdmin = true;
      }
    }
    /* Initiate the form structure */
    this.initForm();
    this.loadData();
  }




  loadData() {
    this.apiService.getService(this.dataUrl,
      '?userId=' + this.userId + '&roleId=' + this.roleId + '&isIndividualSales=' + this.isIndividualSales + '&from_date=' + this.fromDate + '&to_date=' + this.toDate)
      .subscribe(
        res => {
          if (res.Status === 200) {
            const filterDateRange = res.filterDateRange;

            this.fromDate = filterDateRange.fromDate;
            this.toDate = filterDateRange.toDate;

            this.selected = { startDate: moment(this.fromDate), endDate: moment(this.toDate) };

            this.totalRecords = res.TotalRecords;

            if (this.isIndividualSales) {
              this.listData = res.Data[0].deals;
              this.salesPersonName = res.Data[0].user_name;
              this.totalSalesCount = res.Data[0].deals_count;
            } else {
              this.listData = res.Data;
            }

            this.initTable();

          } else if (res.Status !== 200) {
            if (res.filterDateRange) {
              const filterDateRange = res.filterDateRange;

              this.fromDate = filterDateRange.fromDate;
              this.toDate = filterDateRange.toDate;

              this.selected = { startDate: moment(this.fromDate), endDate: moment(this.toDate) };
            }
            this.totalRecords = 0;
            this.listData = [];
          }
        },
        err => console.log(err)
      );
  }

  initTable() {
    if ($.fn.DataTable.isDataTable('#cashAdvanceTable')) {
      this.destroyTable();
    }
    var buttons = [];
    if (this.isAdmin) {
      buttons.push({
        extend: 'print',
        // footer: true,
        // autoPrint: true,
        exportOptions: {
          columns: [0, 1, 2, 3]
        }
      })

    }
    setTimeout(() => {
      $(() => {
        var self = this;
        this.oTable = $('#cashAdvanceTable').DataTable({
          'paging': true,
          'lengthChange': false,
          // 'searching': false,
          'ordering': true,
          'info': true,
          'autoWidth': false,
          'processing': true,
          'pageLength': 10,
          // 'dom': 'lrtip',
          'dom': 'Bfrtip',
          // 'buttons': [
          //     'print'
          // ],
          'buttons': buttons
          // {

          //   "text": 'Email',
          //   exportOptions: {
          //     columns: [0, 1, 2, 3]
          //   },
          //   action: function (e, dt, node, conf) {
          //     var data = dt.buttons.exportData({
          //       "stripHtml": true,
          //       "columns": ':visible',
          //       "modifier": {
          //         "selected": true
          //       }
          //     });
          //     console.log(data);
          //     console.log("dt", dt.draw());
          //     var headerArray = data.header;
          //     var rowsArray = data.body;
          //     var rowItem = '';
          //     var innerRowItem = '';

          //     // console.log("header", headerArray)
          //     // console.log("rowsArray", rowsArray)
          //     // innerRowItem = headerArray + '\n';

          //     for (var h = 0, hen = rowsArray.length; h < hen; h++) {
          //       var innerRowsArray = rowsArray[h];

          //       for (var i = 0, ien = innerRowsArray.length - 1; i < ien; i++) {
          //         var outerCount = [i];

          //         var checker = 'false';
          //         // for (var j = 0, jen = headerArray.length - 1; j < ien; j++) {
          //         // if ( outerCount = [j] & checker == 'false') {
          //         // checker = 'true';
          //         innerRowItem += headerArray[i];
          //         // }
          //         // }

          //         if (innerRowsArray[i] != '') {
          //           innerRowItem += ': ';
          //         }

          //         innerRowItem += innerRowsArray[i];

          //         if (innerRowsArray[i] != '') {
          //           innerRowItem += '\n';
          //         }

          //       };

          //       innerRowItem += '\n';



          //     };
          //     // console.log("inner item", typeof innerRowItem)
          //     // var pctArr = innerRowItem.split(',');

          //     // var table = "<html><body><table>";
          //     // for (var i = 0; i < headerArray.length-1; i++) {
          //     //   table = table + "<thead><tr><th>" + headerArray[i] + "</th><tr></thead>"
          //     // }
          //     // for (var i = 0; i < rowsArray.length; i++) {
          //     //   var row = rowsArray[i];
          //     //   for (var j = 0; j < row.length; j++) {
          //     //     table = table + "<tbody><tr><td>" + rowsArray[i][j] + "</td></tr></tbody>";
          //     //   }
          //     // }
          //     // table = table + "</table></body></html>";
          //     // console.log("table", table)
          //     self.emailCashAdv(innerRowItem);
          //   }


          // },

          // {

          //   extend: 'pdfHtml5',
          //   text:'SEND EMAIL',
          //   // footer: true,
          //   // autoPrint: true,
          //   exportOptions: {
          //     columns: [0, 1, 2, 3]
          //   },
          //   customize: function ( doc ) {
          //     console.log("doc", doc)
          //   }

          // },


        });
      });
    }, 1);
  }

  searchDatatable(val) {
    this.oTable.search(val).draw();
  }

  openModal(template: TemplateRef<any>) {
    this.initTable();

    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }


  gotoDeals(user_id, role_id) {
    window.open(this.pageUrl + '?user=' + user_id + '&user_role=' + role_id + '&from_date=' + this.fromDate + '&to_date=' + this.toDate, '_blank');
  }


  ChangeDate(e) {
    if ($.fn.DataTable.isDataTable('#cashAdvanceTable')) {
      this.destroyTable();
    }
    if (e.startDate && e.endDate) {
      this.fromDate = moment(e.startDate).format('YYYY-MM-DD');
      this.toDate = moment(e.endDate).format('YYYY-MM-DD');

      this.loadData();
    }
  }

  clearDateFilter() {
    this.selected = null; // now we can do
    this.fromDate = '';
    this.toDate = '';

    this.loadData();
  }

  destroyTable() {
    this.oTable.clear().destroy();
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  //added by sneha

  initForm() {
    this.newFieldsForm = this.fb.group({
      form_fields: this.fb.array([]),
      id: ''
    });
  }

  get formFields() {
    return this.newFieldsForm.get('form_fields') as FormArray;
  }


  addFormField() {
    this.formFields.push(
      this.fb.group({
        amount: '',
        date: '',
        memo: '',
        recId: ''
      })
    );
  }

  deleteIDMSField(index) {
    this.formFields.removeAt(index);
  }

  opencashAddModal(template: TemplateRef<any>, row) {
    this.currentId = row.cash_advance_userId;
    this.totalAmount = row.cash_advance_amount;
    this.salesPersonName = row.user_name;

    this.initForm();

    this.newFieldsForm.patchValue({
      id: this.currentId
    });


    this.modalRef1 = this.modalService.show(template, { class: 'add-commission-popup' });

    this.apiService.getService(this.constantService.APIConfig.GETCOMMISSIONHISTORY, '?id=' + this.currentId)
      .subscribe(
        res => {
          if (res.Status === 200) {
            res.Data.forEach(item => {
              const dateIS = item.pay_date.split('-');
              const y = Number(dateIS[0]);
              const m = Number(dateIS[1]);
              const d = Number(dateIS[2]);

              this.formFields.push(this.fb.group(
                {
                  amount: item.amount,
                  memo: item.memo,
                  date: { year: y, month: m, day: d },
                  recId: item.id
                }
              ));
            });
          } else this.addFormField();
        },
        err => console.log('error in gettting commission history')
      );
  }

  submitForm() {
    this.apiService.postService(this.constantService.APIConfig.ADDCOMMISSION, this.newFieldsForm.value)
      .subscribe(
        res => {
          if (res.Status === 201) {
            // if ($.fn.DataTable.isDataTable('#cashAdvanceTable')) {
            //   this.destroyTable();
            // }
            // setTimeout(() => {
            // }, 1000);
            // setTimeout(() => {
            // this.initTable();
            // setTimeout(() => {
            this.loadData();
            //   }, 500);
            // }, 500);
            // $('#addModel').modal('hide');
            this.modalRef1.hide();
          }
        },
        err => console.log('err ', err)
      );
  }

  emailCashAdv1(value) {
    // var thehtml = "";

    // $('#cashAdvanceTable').on( 'draw.dt', function () {
    //   thehtml = $( "#cashAdvanceTable" ).html();
    // });
    // var dt = $('#cashAdvanceTable').dataTable().html();
    // // var footer = dt.api().columns().footer()[4].innerHTML;
    // var rows = dt.fnGetNodes();

    // var rowStr = "";
    // for (var i = 0; i < rows.length; i++) {
    //   rowStr += rows[i].innerHTML;
    //   if (i < rows.length - 1) {
    //     rowStr += "\n";
    //   }
    // }

    // var html = '<table cellspacing="1" cellpadding="1">' + dt + '</table>';
    let data = {
      data: value
    }
    // console.log("o table", this.oTable)
    this.apiService.postService(this.constantService.APIConfig.EMAILCASHADVANCE, data)
      .subscribe(
        res => {
          if (res.Status === 201) {

          }
        },
        err => console.log('err ', err)
      );


  }


  // getPrint() {
  //   var divToPrint = $('#testreportTable');
  //   var htmlToPrint = '' +
  //     '<style type="text/css">' +
  //     'table th, table td {' +
  //     'border:1px solid #000;' +
  //     'padding;0.5em;' +
  //     '}' +
  //     '.pagination{' +
  //     'display:none;' +
  //     '}' +
  //     '.dataTables_filter{' +
  //     'display:none;' +
  //     '}' +
  //     '</style>';
  //   var oSettings = ops_table.fnSettings();
  //   oSettings._iDisplayLength = -1;
  //   ops_table.fnDraw();
  //   var newWin = window.open('', 'Print-Window');
  //   newWin.document.open();
  //   newWin.$(document.body).append('<html><body onload="window.print()">' + htmlToPrint + divToPrint.innerHTML + '</body></html>');
  //   oSettings._iDisplayLength = 10;
  //   ops_table.fnDraw();
  //   newWin.document.close();
  //   setTimeout(function () { newWin.close(); }, 10);
  // }

  emailCashAdv() {
    this.formData = new FormData();
    let head = [['Sales Person','Dept', 'Total Count', 'Cash Advance', 'Date']]
    var doc = new jsPDF('p', 'mm', 'a4');

    var columns = [
      { title: "Sales Person", dataKey: "user_name" },
      { title: "Dept", dataKey: "role_id" },
      { title: "Total Count", dataKey: "deals_count" },
      { title: "Cash Advance", dataKey: "cash_advance_amount" },
      { title: "Date", dataKey: "cash_advance_date" }
    ];

    doc.setFontSize(18);
    doc.text('', 11, 8);
    doc.setFontSize(11);
    doc.setTextColor(100);
    var result = [];

    // for (var i in this.listData) {
    //   // console.log(JSON.stringify(this.listData[i]));
    //   for (var j in this.listData[i]) {
    //     result.push(this.listData[i][j]);
    //   }
    // }
    // console.log("list data", result);


    // (doc as any).autoTable({
    //   head: head,
    //   body: [[this.listData]],
    //   // headStyles:{ fillColor: [255, 0, 0] },
    //   theme: 'plain',
    //   didDrawCell: data => {
    //     // console.log(data.column.index)
    //   }
    // })

    // const pageCount = doc.internal.pageSize;

    // console.log("pagecount", pageCount)
    // // For each page, print the page number and the total pages
    // for(var i = 1; i <= pageCount; i++) {
    //      // Go to page i
    //     doc.setPage(i);
    //      //Print Page 1 of 4 for example
    //     doc.text(i);
    // }
    var totalPagesExp = "{total_pages_count_string}";

    var header = function (data) {
      doc.setFontSize(20);
      doc.setTextColor(0);
      doc.text('Cash Advance', 15, 15);
      // doc.setFontStyle('normal');
      var str = "Page " + doc.getNumberOfPages()
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str;
      }
      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(str, data.settings.margin.left, pageHeight - 10);
      // doc.text(str, null, pageHeight - 10,{align: "right"});
    };

    // var currentpage = 1;

    // var footer = function (data) {

    //   console.log("current page", doc.getCurrentPageInfo())

    //     if (currentpage < doc.getNumberOfPages()) {

    //         doc.setFontSize(10);
    //         doc.setTextColor(0);
    //         var str = "Page " + doc.getNumberOfPages();
    //         doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 30);
    //         currentpage = doc.getNumberOfPages();
    //     }
    // };

    (doc as any).autoTable({
      body: this.listData,
      didDrawPage: header,
      // afterPageContent: footer,
      headStyles: {
        lineWidth: 0.06,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0]
        //   lineColor: [217, 216, 216]
      },
      margin: { top: 20 },
      columns: columns,
      orientation: 'portrait',
      theme: 'grid',
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      },
    });


    var file = new File([doc.output()], 'CashAdvance.pdf', { type: "application/pdf" });
    console.log("file", file);
    // self.files = file;
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // console.log("file reader", reader);
    // var blobPDF =  new Blob([ doc.output() ], { type : 'application/pdf'});
    // var blobUrl = URL.createObjectURL(blobPDF); 
    // Open PDF document in new tab
    // doc.output('dataurlnewwindow')

    // Download PDF document  
    // doc.save('CashAdvance.pdf');
    // var base64 = this.getBase64(file);

    // // console.log("base 64", base64)
    // let data = {
    //   base64: base64
    // }
    // doc.getBuffer(function (buffer) {
    //   const dataUrl = URL.createObjectURL(new Blob([buffer], {
    //     type: "application/pdf"
    //   }),
    //   );
    //   // console.log("dataUrl", dataUrl);

    //   var file = new File([buffer], 'document.pdf', { type: "application/pdf" });
    //   // console.log("file", file);
    //   self.files = file;
    // )}
    this.formData.append('file', file);

    this.apiService.postEmailAttachment(this.constantService.APIConfig.EMAILCASHADVANCE, this.formData)
      .subscribe
      ((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            break;
          case HttpEventType.Response:
            // console.log('User successfully created!', event.body);
            let res = event.body;
            if (res.Status == 201) {
              this.alertSvc.success('Email Sent Successfully!');
            } else {
              this.alertSvc.error('Sending Email Failed!');
            }
        }

      },
        err => console.log('err ', err)
      );

    // this.apiService.postService(this.constantService.APIConfig.EMAILCASHADVANCE, data)
    // .subscribe(
    //   res => {
    //     if (res.Status === 201) {
    //     }
    //   },
    //   err => console.log('err ', err)
    // );


  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

}
