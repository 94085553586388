export class ExportSalesUserCommissionColumns {
    public static getColumns(): any {
      return [
        {
          display: 'ID',
          variable: 'id',
          filter: 'text'
        },
        {
          display: 'Commission Id',
          variable: 'commission_id',
          filter: 'text'
        },
        {
          display: 'Amount',
          variable: 'amount',
          filter: 'text'
        },
        {
          display: 'Pay Date',
          variable: 'pay_date',
          filter: 'text'
        },
        {
          display: 'Memo',
          variable: 'memo',
          filter: 'text'
        },
       
      ];
    }
  }
  
  