import { DataSource, CollectionViewer } from '@angular/cdk/collections';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { ApiService } from '../services';
import { catchError, finalize } from 'rxjs/operators';


export class SalesDataSource implements DataSource<any> {

    private salesSubject = new BehaviorSubject<any[]>([]);
    public data$;

    // to show loading
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    // to show the total number of records
    private countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();
    public totalRecords;

    constructor(private apiSvc: ApiService) { }

    loadSales(
        filter: string,
        sort: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        userId: string,
        role: string,
        salesPerson: string,
        financePerson: string,
        dealId: string,
        stock: string,
        customerState: string,
        salesPrice: string,
        vAutoPrice: string,
        mrFee: string,
        eligibleToPay: string,
        flag: string,
        lender: string,
        commissionPaid: string,
        backendCommissionPaid: string,
        isIndividual: boolean,
        acctType: string,
        fromDate: string,
        toDate: string,
        sortByLenderDesc: string
    ) {
        this.loadingSubject.next(true);

        this.apiSvc.findSales(filter, sort, sortDirection, pageIndex, pageSize, userId, role, salesPerson, financePerson, dealId, stock, customerState, salesPrice, vAutoPrice, mrFee, eligibleToPay, flag, lender, commissionPaid, backendCommissionPaid,isIndividual, acctType, fromDate, toDate, sortByLenderDesc)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(result => {
                this.data$ = result['Data'];
                this.totalRecords = result['RecordsTotal'];
                this.salesSubject.next(result['Data']);
                this.countSubject.next(result['RecordsTotal']);
            });
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.salesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.salesSubject.complete();
        this.loadingSubject.complete();
        this.countSubject.complete();
    }

}
