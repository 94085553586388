export class ExportSalesColumns {
  public static getColumns(): any {
    return [
      {
        display: 'STOCK #',
        variable: 'CollateralStockNumber',
        filter: 'text'
      },
      {
        display: 'Sales Person 1',
        variable: 'sales_person_1',
        filter: 'text'
      },
      {
        display: 'Sales Person 2',
        variable: 'sales_person_2',
        filter: 'text'
      },
      {
        display: 'Sales Manager',
        variable: 'sales_manager',
        filter: 'text'
      },
      {
        display: 'FI Person',
        variable: 'f_i_person',
        filter: 'text'
      },
      {
        display: 'Sales Person 1 Commission',
        variable: 'salesPerson1Commission',
        filter: 'text'
      },
      {
        display: 'Sales Person 2 Commission',
        variable: 'salesPerson2Commission',
        filter: 'text'
      },
      {
        display: 'Sales Manager Commission',
        variable: 'salesManagerCommission',
        filter: 'text'
      },
      {
        display: 'FI Person Commission',
        variable: 'FIPersonCommission',
        filter: 'text'
      }
    
    ];
  }
}

