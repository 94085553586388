import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActiveGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService,
  ) { }

  canActivate() {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;
  }

}
