import 'core-js/shim';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ConstantService, ApiService, HelperService } from '@app/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-inspection-graph',
  templateUrl: './inspection-graph.component.html',
  styles: []
})
export class InspectionGraphComponent implements OnInit, OnDestroy {
  private chart: am4charts.PieChart;
  chartData: any = [];
  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  filterOptions = {
    month: '',
    year: ''
  };

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;
    this.loadData();
  }

  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.COUNTINSPECTEDVAECHILESBYUSER, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        this.chartData = data.Data;
        this.initChart();
      }, error => console.log('error in load data in inspection graph component ', error)
    );
  }


  initChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('slicedChart', am4charts.SlicedChart);
      chart.paddingBottom = 30;
      chart.data = this.chartData;

      const series = chart.series.push(new am4charts.PyramidSeries());
      series.dataFields.value = 'inspectedCount';
      series.dataFields.category = 'inspector_name';
      series.alignLabels = true;
      series.valueIs = 'height';
    });
  }

  applyFilter() {
    this.loadData();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}

