import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService, AuthService, ConstantService, HelperService, AlertService } from '@app/core';
import { CustomValidators } from '@app/shared';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
  styleUrls: ['./topnavbar.component.scss']
})
export class TopnavbarComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  modalRef: BsModalRef;
  modelRef: BsModalRef;
  messageTitle = '';
  messageBody = '';
  adjustAmount: '';
  messageId = 0;
  messageSender = '';
  messageDate = '';
  messageDealID = 0;
  messageSenderID = 0;
  messageReceiverID = 0;
  messageStatus = '';
  messageReceiverName = '';
  messsageUpdateTime = '';
  messageRequestMeta: '';
  config: {
    animated: true
  };
  subscriptions: Subscription[] = [];

  userRoleId: any;
  loggedInId: number;
  user_idms_id: number;
  name: string;
  email: string;
  role: string;
  submitted = false;
  unreadMessageCount = 0;
  messages = [];

  unreadNotificatons = 0;
  notifications = [];
  notificationsList = [];
  timeLineID = 0;
  ifByAdmin:boolean = false;

  private sub;
  constructor(
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private apiSvc: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private alertSvc: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private authenticationService: AuthService,
  ) { }

  ngOnInit() {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.name = currentUser.userData.first_name + ' ' + currentUser.userData.last_name;
      this.email = currentUser.userData.email;
      this.role = currentUser.userData.role_name;
      this.userRoleId = currentUser.userData.role_id;
      this.loggedInId = currentUser.userData.id;
      this.user_idms_id = currentUser.userData.idms_id;
      this.ifByAdmin = currentUser.userData.auth ? currentUser.userData.auth : false;
    }

    this.myForm = this.initForm();

    this.sub = interval(10000).pipe().subscribe(val => {
      this.messages = [];
      this.notifications = [];
      this.unreadMessageCount = 0;
      this.unreadNotificatons = 0;
      this.getNewRequests();
      this.getLatestNotifications();
      this.getRequestUpdateNotification();
      if (this.userRoleId === '1') {
        this.getBuyerNotifications();
      }
    });
  }

  initForm(): FormGroup {
    return this.fb.group({
      id: [this.loggedInId],
      role: [this.userRoleId],
      oldPassword: [null, Validators.required],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      confirmPassword: [null, Validators.compose([Validators.required])]
    }, { validator: CustomValidators.passwordMatchValidator });
  }

  logout() { this.authenticationService.logout(); }

  openModal(template: TemplateRef<any>) {
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        this.resetForm();
      })
    );
    this.modelRef = this.modalService.show(template, this.config);
  }


  onSubmitForm() {
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }

    this.apiSvc.putService(this.constantSvc.APIConfig.UPDATEPASSWORD, this.myForm.value)
      .subscribe(
        data => {
          this.modelRef.hide();
          if (data.message) {
            this.alertSvc.success(data.message ? data.message : 'Password successfully updated!');
          } else if (data.error) {
            this.alertSvc.error(data.error ? data.error : 'Something went wrong please try again');
          }
        }, error => this.alertSvc.error(error.error.message ? error.error.message : 'Something went wrong please try again')
      );
  }

  resetForm() {
    this.submitted = false;
    this.myForm.reset({ id: this.loggedInId, role: this.userRoleId });
  }

  // Get all notifications regarding deal when deal add to watch
  getLatestNotifications() {
    this.apiSvc.getNotifications(this.constantSvc.APIConfig.GETNOTIFICATIONS, '?user_id=' + this.user_idms_id + '&role=' + this.userRoleId)
      .subscribe(
        res => {
          if (res.Status === 200) {
            this.unreadNotificatons = this.unreadNotificatons + res.count;
            if (res !== undefined) {
              res.Data.forEach(item => this.notifications.push(item));
            }
          }
        },
        (error: Response) => {
          console.log('error in get notification ', error);
        }
      );
  }

  // get all notification when someone tag
  getBuyerNotifications() {
    this.apiSvc.getNotifications(this.constantSvc.APIConfig.GETBUYERNOTIFICATIONS, '?email=' + this.email).subscribe(
      res => {
        if (res.Status === 200) {
          this.unreadNotificatons = this.unreadNotificatons + res.count;
          if (res !== undefined) {
            res.Data.forEach(item => this.notifications.push(item));
          }
        }
      }, err => console.log('error in get notification ', err)
    );
  }

  // get new commission adjuct request
  getNewRequests() {
    this.apiSvc.getRequests(this.constantSvc.APIConfig.GETREQUESTS, '?user_id=' + this.user_idms_id).subscribe(
      res => {
        if (res.Status === 200) {
          this.unreadMessageCount = this.unreadMessageCount + res.count;
          if (res !== undefined) {
            res.Data.forEach(item => this.messages.push(item));
          }
        }
      }, err => console.log('err from received ', err)
    );
  }

  // when someone react on request sended by me
  getRequestUpdateNotification() {
    this.apiSvc.getRequestUpdateNotification(this.constantSvc.APIConfig.GETREQUESTUPDATENOTIFICATION, '?user_id=' + this.user_idms_id + '&role=' + this.userRoleId).subscribe(
      res => {
        if (res.Status === 200) {
          this.unreadMessageCount = this.unreadMessageCount + res.count;
          if (res !== undefined) {
            res.Data.forEach(item => this.messages.push(item));
          }
        }
      }, err => console.log('error in get notification ', err)
    );
  }

  // update notification status
  updateNotificationStatus(dealId) {
    const param = {
      'deal_id': dealId,
      'user_id': this.user_idms_id
    };
    this.apiSvc.putService(this.constantSvc.APIConfig.UPDATENOTIFICATIONSTATUS, param).subscribe(
      res => {
        this.notifications = [];
        this.unreadNotificatons = 0;
        this.getLatestNotifications();
        this.getLatestNotifications();
      },
      err => console.log('error when update notification ', err)
    );
  }

  // update notification status
  updateBuyerNotificationStatus(stock, user_id) {
    const param = {
      'stock': stock,
      'user_id': user_id
    };
    this.apiSvc.putService(this.constantSvc.APIConfig.UPDATEBUYERNOTIFICATIONSTATUS, param).subscribe(
      res => this.getBuyerNotifications(),
      err => console.log('error when update notification ', err)
    );
  }


  // Open popup modal
  confirmPopupModal(template: TemplateRef<any>, msg) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.messageId = msg.id;
    this.messageTitle = msg.title;
    this.adjustAmount = msg.adjust_amount;
    this.messageBody = msg.message;
    this.messageSender = msg.sender_name;
    this.messageDate = msg.created_at;
    this.messageDealID = msg.deal_id;
    this.messageSenderID = msg.request_by;
    this.messageReceiverID = msg.request_to;
    this.messageStatus = msg.status;
    this.messageReceiverName = msg.receiver_name;
    this.messsageUpdateTime = msg.updated_at;
    this.messageRequestMeta = msg.request_meta;
  }

  // update request return feedback status to seen
  updateRequestNotificationStatus(msg) {
    const param = {
      'id': msg.id,
      'seen': 1
    };
    this.apiSvc.putService(this.constantSvc.APIConfig.UPDATEREQUESTSEENSTATUS, param).subscribe(
      res => {
        if (res.Status === 200) {
          this.messages = [];
          this.unreadMessageCount = 0;

          this.getNewRequests();
          this.getRequestUpdateNotification();
        }
      }, err => console.log('there are something wrong in update request notification status')
    );
  }

  notificationPopupModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeNotificationPopupModel() {
    this.modalRef.hide();
  }


  // for confirm commission adjuct request
  confirm(id): void {
    const params = {
      'id': id,
      'status': 1,
      'deal_id': this.messageDealID,
      'amount': this.adjustAmount,
      'request_by': this.messageSenderID,
      'request_to': this.messageReceiverID
    };

    this.modalRef.hide();
    this.apiSvc.putService(this.constantSvc.APIConfig.REQUESTREPLY, params).subscribe(
      res => {
        if (res.Status === 200) {
          this.messages = [];
          this.unreadMessageCount = 0;

          this.getNewRequests();
          this.getRequestUpdateNotification();
          this.alertSvc.success('success');
        } else if (res.Status === 204) {
          this.alertSvc.error('Something wrong please try again');
        }
      }, err => console.log('err for update request ', err)
    );
  }

  // for Declined commission adjuct request
  decline(id): void {
    const params = {
      'id': id,
      'status': 2
    };
    this.modalRef.hide();
    this.apiSvc.putService(this.constantSvc.APIConfig.REQUESTDECLINE, params).subscribe(
      res => {
        if (res.Status === 200) {
          this.messages = [];
          this.unreadMessageCount = 0;

          this.getNewRequests();
          this.getRequestUpdateNotification();
          this.alertSvc.success('success');
        } else if (res.Status === 204) {
          this.alertSvc.error('Something wrong please try again');
        }
      }, err => console.log('err for update request ', err)
    );
  }

  getRequestType(type) {
    if (type === 'by_amount') {
      return 'By Amount';
    } else if (type === '(backEndProfit) * (.25) * (salesGroupPersonCommission)') {
      return 'Back End Split';
    } else if (type === '(frontEndProfit) * (.25) * (salesGroupPersonCommission)') {
      return 'Front End Split';
    } else if (type === '(profitOnWarranty)  * (.25) * (salesGroupPersonCommission)') {
      return 'Warranty Split';
    } else if (type === '(profitOnDebtCancellation) * (.25) * (salesGroupPersonCommission)') {
      return 'Debt Cancellation Split';
    }
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  backToAdmin(){
    this.authenticationService.backToAdmin();

    const dashboardURL = this.constantSvc.dashboardConfig.DASHBOARD_URL;
    const currentUser = this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER);
    const currentUserRole = currentUser.userData.role_id;

    if (currentUserRole === '1') {
      this.helperSvc.lsSetItem(dashboardURL, 'admin');
    }
    this.router.navigate(['/dashboard'])
    .then(() => {
        window.location.reload();
     });

    
  }

}
