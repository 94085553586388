import 'core-js/shim';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ConstantService, ApiService, HelperService } from '@app/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
am4core.useTheme(am4themes_animated);
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-inspection-chart',
  templateUrl: './inspection-chart.component.html',
  styles: []
})
export class InspectionChartComponent implements OnInit, OnDestroy {
  private chart: am4charts.PieChart;
  chartData: any = [];
  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  filterOptions = {
    month: '',
    year: ''
  };
  user_idms_id: any = 0;
  userRoleId: any = 0;
  userName: string;
  totalSales = 0;
  totalMySales = 0;

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    const currentUser = this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER);
    this.userName = currentUser.userData.first_name + ' ' + currentUser.userData.last_name;

    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;

    this.loadData();
  }

  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.COUNTTOTALCARINSPECTED, '?inspector=' + this.userName + '&month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        this.chartData = data.Data;
        this.initChart();
      }, error => console.log('error in load Sales by buyers function ', error)
    );
  }

  applyFilter() {
    this.loadData();
  }

  initChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('inspectorPieChart', am4charts.PieChart);
      chart.responsive.enabled = true;
      chart.marginTop = 25;
      chart.radius = 100;
      chart.data = this.chartData;

      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'inspectedCount';
      pieSeries.dataFields.category = 'status';
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.tooltipText = '{inspectedCount} Cars {status}';
      pieSeries.labels.template.text = '{inspectedCount} Cars {status}';
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

    });
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}


