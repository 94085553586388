export class ExportBackendCommissionColumns {
    public static getColumns(): any {
      return [
        {
          display: 'SALESMAN_ID',
          variable: 'sales_person',
          filter: 'text'
        },
        {
          display: 'SALESMAN_NAME',
          variable: 'name',
          filter: 'text'
        },
        {
          display: 'TOTAL_COMMISSION',
          variable: 'commission_amount',
          filter: 'text'
        },
        {
            display: 'TOTAL_PAID',
            variable: 'total_paid',
            filter: 'text'
        },
        {
          display: 'REMAINING_COMMISSION',
          variable: 'remaining_amount',
          filter: 'text'
        }
    
      ];
    }
  }
  
  