import { Component, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit() {
    document.body.className = 'hold-transition sidebar-mini sidebar-collapse';
    $.widget.bridge('uibutton', $.ui.button);
  }

  ngOnDestroy() {
    document.body.className = '';
  }
}
