import { Component, OnInit } from '@angular/core';
import { HelperService, ConstantService, AuthService } from '@app/core';

declare var $: any;
@Component({
  selector: 'app-asidenavbar',
  templateUrl: './asidenavbar.component.html',
  styleUrls: ['./asidenavbar.component.scss']
})
export class AsidenavbarComponent implements OnInit {
  currentUser: any;
  userName: string;
  lastName: string;
  userRoleId: any;
  filePath: any;
  dashboardUrl: any;

  constructor(
    private helperSvc: HelperService,
    private constantSvc: ConstantService,
    private authenticationService: AuthService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {

    const currentUser = this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER);
    this.userName = currentUser.userData.first_name;
    this.lastName = currentUser.userData.last_name;
    this.userRoleId = currentUser.userData.role_id;
    this.filePath = currentUser.user_pic;

    this.dashboardUrl = '/dashboard';
  }

  logout() {
    this.authenticationService.logout();
  }

}
