import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { MaterialModule } from './material.module';

// multiselect
import { NgSelectModule } from '@ng-select/ng-select';

import { polyfill as keyboardEventKeyPolyfill } from 'keyboardevent-key-polyfill';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
keyboardEventKeyPolyfill();

import {
  faAsterisk,
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faPlayCircle,
  faRocket,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faRedo,
  faSpinner,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAsterisk,
  faBars,
  faUserCircle,
  faPowerOff,
  faCog,
  faRocket,
  faPlayCircle,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook,
  faRedo,
  faSpinner,
  faSyncAlt
);

import { PercentPipe } from './pipes/percent.pipe';
import { NiceDateFormatPipe } from './pipes/nice-date-format.pipe';
import { SortTierPipe } from './pipes/sort-tier.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SplitPipe } from './pipes/split.pipe';

import { AlertComponent } from './components/alert/alert.component';
import { ControlMessagesComponent } from './components/control-messages/control-messages.component';
import { SalesChartComponent } from './components/sales-chart/sales-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { SalesTableComponent } from './components/sales-table/sales-table.component';
import { SalesPersonsTableComponent } from './components/sales-persons-table/sales-persons-table.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { UsersAutoSearchComponent } from './components/users-auto-search/users-auto-search.component';
import { UsersTierPieChartComponent } from './components/users-tier-pie-chart/users-tier-pie-chart.component';
import { UsersCommissionTableComponent } from './components/users-commission-table/users-commission-table.component';
import { BuyersSaleTableComponent } from './components/buyers-sale-table/buyers-sale-table.component';
import { BuyersSalesGraphChartComponent } from './components/buyers-sales-graph-chart/buyers-sales-graph-chart.component';
import { BuyersTierPieChartComponent } from './components/buyers-tier-pie-chart/buyers-tier-pie-chart.component';
import { BuyersSalesPieChartComponent } from './components/buyers-sales-pie-chart/buyers-sales-pie-chart.component';
import { BuyerOfMonthTableComponent } from './components/buyer-of-month-table/buyer-of-month-table.component';
import { BuyersCommissionTableComponent } from './components/buyers-commission-table/buyers-commission-table.component';
import { MonthlySalesGraphComponent } from './components/monthly-sales-graph/monthly-sales-graph.component';

import { InspectionChartComponent } from './components/inspection-chart/inspection-chart.component';
import { InspectionGraphComponent } from './components/inspection-graph/inspection-graph.component';
import { InspectorsCommissionTableComponent } from './components/inspectors-commission-table/inspectors-commission-table.component';
import { InspectionTableComponent } from './components/inspection-table/inspection-table.component';
import { PodiumStatsComponent } from './components/podium-stats/podium-stats.component';
import { BackendSalesTableComponent } from './components/backend-sales-table/backend-sales-table.component';
import { BackendSalesPersonsTableComponent } from './components/backend-sales-persons-table/backend-sales-persons-table.component';

@NgModule({
  declarations: [
    AlertComponent,
    ControlMessagesComponent,
    SalesChartComponent,
    PieChartComponent,
    SalesTableComponent,
    SalesPersonsTableComponent,
    PercentPipe,
    NiceDateFormatPipe,
    SortTierPipe,
    FilterPipe,
    SplitPipe,
    ReadMoreComponent,
    UsersAutoSearchComponent,
    UsersTierPieChartComponent,
    UsersCommissionTableComponent,
    BuyersSaleTableComponent,
    BuyersSalesGraphChartComponent,
    BuyersTierPieChartComponent,
    BuyersSalesPieChartComponent,
    BuyerOfMonthTableComponent,
    BuyersCommissionTableComponent,
    MonthlySalesGraphComponent,
    InspectionChartComponent,
    InspectionGraphComponent,
    InspectorsCommissionTableComponent,
    InspectionTableComponent,
    PodiumStatsComponent,
    BackendSalesTableComponent,
    BackendSalesPersonsTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule.forRoot(), // forRoot is deprecated: 3.0.0
    FontAwesomeModule,
    MaterialModule,
    SatPopoverModule,
    ColorPickerModule,
    AngularDualListBoxModule,
    TooltipModule.forRoot(),
    TextInputAutocompleteModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule
  ],
  exports: [
    NgbModule,
    DatePipe,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    MaterialModule,
    TextInputAutocompleteModule,
    AlertComponent,
    ControlMessagesComponent,
    SalesChartComponent,
    PieChartComponent,
    SalesTableComponent,
    SalesPersonsTableComponent,
    ReadMoreComponent,
    UsersAutoSearchComponent,
    UsersTierPieChartComponent,
    UsersCommissionTableComponent,
    PercentPipe,
    NiceDateFormatPipe,
    SortTierPipe,
    FilterPipe,
    SplitPipe,
    SatPopoverModule,
    ColorPickerModule,
    AngularDualListBoxModule,
    NgxDaterangepickerMd,
    TooltipModule,
    BuyersSaleTableComponent,
    BuyersSalesGraphChartComponent,
    BuyersTierPieChartComponent,
    BuyersSalesPieChartComponent,
    BuyerOfMonthTableComponent,
    BuyersCommissionTableComponent,
    MonthlySalesGraphComponent,
    InspectionChartComponent,
    InspectionGraphComponent,
    InspectorsCommissionTableComponent,
    InspectionTableComponent,
    PodiumStatsComponent,
    NgSelectModule,
    BackendSalesTableComponent,
    BackendSalesPersonsTableComponent
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
