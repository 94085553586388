import { Component, Input, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'app-read-more',
  template: `
  <div class="text-container" [innerHTML]="currentText"></div>
  <a *ngIf="isShowLinks" class="read-more-tag" [class.hidden]="hideToggle" (click)="toggleView()"> Read {{isCollapsed? 'more':'less'}}</a>
`,
  styles: [`
  .text-container{
      display : inherit;
  }
  .read-more-tag {
      color:#0400ff !important;
      cursor:pointer;
  }
`],
})
export class ReadMoreComponent implements OnChanges {

  @Input() text: string;
  @Input() maxLength = 100;
  currentText: string;
  hideToggle = true;

  public isCollapsed = true;
  isShowLinks = false;

  constructor(private elementRef: ElementRef) { }

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    const textLength = this.text ? this.text.length : '';
    // console.log('textLength ', textLength);
    if (!this.text || textLength <= this.maxLength) {
      // console.log('i am in if');
      this.currentText = this.text;
      this.isCollapsed = false;
      this.hideToggle = true;
      this.isShowLinks = false;
      return;
    }

    // console.log('i am outside if');

    this.hideToggle = false;
    if (this.isCollapsed === true) {
      this.currentText = this.text.substring(0, this.maxLength) + '...';
      this.isShowLinks = true;
    } else if (this.isCollapsed === false) {
      this.currentText = this.text;
      this.isShowLinks = true;
    }

  }

  ngOnChanges() {
    this.determineView();
  }
}
