import 'core-js/shim';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ApiService, ConstantService, HelperService } from '@app/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as moment from 'moment';

@Component({
  selector: 'app-buyers-sales-graph-chart',
  templateUrl: './buyers-sales-graph-chart.component.html',
})
export class BuyersSalesGraphChartComponent implements OnInit, OnDestroy {

  private chart: am4charts.PieChart;

  totalSalesCount = 0;
  totalSalesCash = 0;
  totalSalesFinance = 0;
  totalSalesOutsideFinance = 0;

  cash_deal_goal = 0;
  in_house_deal_goal = 0;
  outside_deal_goal = 0;

  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  filterOptions = {
    month: '',
    year: ''
  };

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;

    this.initSalesChart();
    this.getSalesGoal();
    this.countTotal();
  }


  countTotal(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.BUYERCOUNTSALES, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      data => {
        if (data.Status === 200) {
          this.totalSalesCash = data.Data['C'];
          this.totalSalesFinance = data.Data['I'];
          this.totalSalesOutsideFinance = data.Data['O'];
          this.totalSalesCount = data.Data['total'];
        } else {
          this.totalSalesCash = 0;
          this.totalSalesFinance = 0;
          this.totalSalesOutsideFinance = 0;
          this.totalSalesCount = 0;
        }
        this.initSalesChart();
      }, error => console.log('Problem in count all current month sales in dashboard')
    );
  }

  getSalesGoal(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETSETTINGS, '').subscribe(
      data => {
        this.cash_deal_goal = data.data[26].meta_value;
        this.in_house_deal_goal = data.data[27].meta_value;
        this.outside_deal_goal = data.data[28].meta_value;
      }, error => console.log('error occurred!')
    );
  }

  initSalesChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('buyersSalesGraphChartDiv', am4charts.XYChart);
      chart.height = 200;
      chart.data = [{
        'type': 'Cash',
        'sales': this.totalSalesCash
      }, {
        'type': 'In-House',
        'sales': this.totalSalesFinance
      }, {
        'type': 'Outside Finance',
        'sales': this.totalSalesOutsideFinance
      }];

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'type';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = 'sales';
      series.dataFields.categoryX = 'type';
      series.name = 'sales';
      series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
      series.columns.template.fillOpacity = .8;

      const columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;

    });
  }

  applyFilter() {
    this.countTotal();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}

