import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConstantService, ApiService, HelperService } from '@app/core';
import { ExportCommissionColumns } from '../../utility/export-commission-columns';
import { ExportUtility } from '../../utility/export-utility';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
declare var $: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-inspectors-commission-table',
  templateUrl: './inspectors-commission-table.component.html',
  styleUrls: ['./inspector-commission-table.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class InspectorsCommissionTableComponent implements OnInit, AfterViewInit {

  newFieldsForm: FormGroup;
  public currentMonth: string;
  public currentYear: string;

  years: any = [];
  months: any = [];

  filterOptions = {
    month: '',
    year: ''
  };

  public fields: Array<any> = [];
  currentId: any;
  totalCommission: any;
  inspector: any;
  commissionRecords: any = [];
  oTable: any;
  constructor(
    private fb: FormBuilder,
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService
  ) { }


  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;


    /* Initiate the form structure */
    this.initForm();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  initForm() {
    this.newFieldsForm = this.fb.group({
      form_fields: this.fb.array([]),
      id: ''
    });
  }

  get formFields() {
    return this.newFieldsForm.get('form_fields') as FormArray;
  }

  addFormField() {
    this.formFields.push(this.fb.group({ amount: '', date: '', memo: '', recId: '' }));
  }

  deleteIDMSField(index) {
    this.formFields.removeAt(index);
  }


  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETINSPECTORSCOMMISSIONS, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      res => {
        if (res.Status === 200) {
          this.commissionRecords = res.Data;
          this.initTable();
        } else {
          this.commissionRecords = [];
        }
      }, err => console.log('error in getting commission records ', err)
    );
  }

  getInfo(row) {
    this.currentId = row.id;
    this.totalCommission = row.amount;
    this.inspector = row.inspector;
    this.initForm();
    this.newFieldsForm.patchValue({
      id: this.currentId
    });
    this.apiSvc.getService(this.constantSvc.APIConfig.GETINSPECTORSCOMMISSIONPAIDHISTORY, '?id=' + this.currentId).subscribe(
      res => {
        if (res.Status === 200) {
          res.Data.forEach(item => {
            const dateIS = item.pay_date.split('-');
            const y = Number(dateIS[0]);
            const m = Number(dateIS[1]);
            const d = Number(dateIS[2]);

            this.formFields.push(this.fb.group(
              {
                amount: item.amount,
                memo: item.memo,
                date: { year: y, month: m, day: d },
                recId: item.id
              }
            ));
          });
        } else {
          this.addFormField();
        }
      }, err => console.log('error in gettting commission history')
    );
  }

  onSubmit() {
    this.apiSvc.postService(this.constantSvc.APIConfig.ADDINSPECTORCOMMISSION, this.newFieldsForm.value).subscribe(
      res => {
        if (res.Status === 201) {
          if ($.fn.DataTable.isDataTable('#usersTable')) {
            this.destroyTable();
          }
          this.loadData();
          $('#addCommModel').modal('hide');
        }
      }, err => console.log('err ', err)
    );
  }

  searchFilter(val) {
    const value = val ? val.trim() : '';
    this.oTable.search(value).draw();
  }

  initTable() {
    $(() => {
      this.oTable = $('#inspectorsTable').DataTable({
        'paging': true,
        'lengthChange': false,
        'searching': false,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'processing': true,
        'pageLength': 5
      });
    });
  }

  destroyTable() {
    this.oTable.clear().destroy();
  }

  applyFilter() {
    if ($.fn.DataTable.isDataTable('#usersTable')) {
      this.destroyTable();
    }
    this.loadData();
  }

  exportFile(): void {
    let history;
    const todayDate = moment().format('MM/DD/YYYY');
    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTCOMMISSIONHISTORY, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year).subscribe(
      res => {
        if (res.Status === 200) {
          history = res.Data;

          const collection: any[] = [];
          const columns: any[] = ExportCommissionColumns.getColumns();
          const exprtcsv: any[] = [];
          (<any[]>JSON.parse(JSON.stringify(history))).forEach(rec => {
            let rowCount = 1;
            rec.history.forEach(element => {
              const row = new Object();
              for (let i = 0; i < columns.length; i++) {
                const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);
                row[columns[0].display] = rec.vendor_id;
                row[columns[1].display] = todayDate;
                row[columns[2].display] = todayDate;
                row[columns[3].display] = rowCount;
                row[columns[4].display] = element.memo;
                row[columns[5].display] = '260000';
                row[columns[6].display] = '100';
                row[columns[7].display] = '-' + element.amount;
                row[columns[8].display] = '';
              }

              ++rowCount;
              exprtcsv.push(row);
            });
          });

          ExportUtility.downloadcsv(exprtcsv, 'commissions-report_');
        } else {
          console.log('there are something wrong in get History ');
        }
      }, err => console.log('error in export commission history ', err)
    );

  }

}
