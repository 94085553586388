import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ConstantService, AlertService } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Output() Email = new EventEmitter<any>();
  forgetForm: FormGroup;
  departmentType: any = 'Sales Department';
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private _constantSvc: ConstantService,
    private _router: Router,
    private _alertSvc: AlertService,
    private _authSvc: AuthService
  ) { }

  ngOnInit() {
    document.body.className = 'hold-transition login-page';
    this.buildForm();
  }

  get f() {
    return this.forgetForm.controls;
  }

  forgotPassword() {
    this.submitted = true;
    if (this.forgetForm.invalid) {
      return;
    }
    const jsonData = {
      loginkey: this.forgetForm.value.email,
      department: this.forgetForm.value.department
    };
    this._authSvc.forget(this._constantSvc.APIConfig.FORGET, jsonData).subscribe(
      (res: any) => {
        if (res.Status === 200) {
          this._alertSvc.success(res.message);
          this._router.navigate(['/login']);
        } else {
          this._alertSvc.error(res.error);
        }
      }, (err: any) => {
        console.log('error ', err);
      }
    );
  }

  changeDepartment(val) {
    const value = val.trim();
    this.departmentType = (value === 'sales') ? 'Sales Department' : 'Buyers Department';
    this.forgetForm.patchValue({
      department: this.departmentType
    });
  }

  private buildForm(): void {
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      department: this.departmentType,
    });
  }

  ngOnDestroy() {
    document.body.className = '';
  }

}
