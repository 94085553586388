import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ApiService, ConstantService } from '@app/core';

@Component({
  selector: 'app-users-auto-search',
  templateUrl: './users-auto-search.component.html',
  styleUrls: ['./users-auto-search.component.scss']
})
export class UsersAutoSearchComponent implements OnInit {

  results: any[] = [];
  queryField: FormControl = new FormControl();

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService
  ) { }

  ngOnInit() {
    this.queryField.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((query) => this.apiSvc.getService(this.constantSvc.APIConfig.SEARCHUSER, '?queryString=' + query))
      ).subscribe(result => {
        if (result.Status === 404) { this.results = []; } else { this.results = result.searchResult; }
      });
  }

  onClick(name) {
    this.queryField.setValue(name);
    this.results = [];
  }

}
