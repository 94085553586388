import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConstantService, ApiService, HelperService, AlertService } from '@app/core';
import { ExportCommissionColumns } from '../../utility/export-commission-columns';
import { ExportBackendUserCommissionColumns } from '../../utility/export-backenduser-commission-column';
import { ExportSalesUserCommissionColumns } from '../../utility/export-salesuser-commission-column';
import { ExportBackendCommissionColumns } from '../../utility/export-backend-commission-columns';
import { ExportUtility } from '../../utility/export-utility';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
declare var $: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-users-commission-table',
  templateUrl: './users-commission-table.component.html',
  styleUrls: ['./users-commission-table.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class UsersCommissionTableComponent implements OnInit, AfterViewInit {
  newFieldsForm: FormGroup;
  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  fields: Array<any> = [];
  currentId: any;
  totalCommission: any;
  salesPersonName: any;
  commissionRecords: any = [];
  oTable: any;
  filterOptions = {
    month: '',
    year: '',
    type: ''
  };
  stockList:any = [];

  constructor(
    private fb: FormBuilder,
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private alertSvc: AlertService,

  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();

    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();

    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;
    this.filterOptions.type = 'commission';

    /* Initiate the form structure */
    this.initForm();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  initForm() {
    this.newFieldsForm = this.fb.group({
      form_fields: this.fb.array([]),
      id: ''
    });
  }


  get formFields() {
    return this.newFieldsForm.get('form_fields') as FormArray;
  }

  addFormField() {
    if (this.filterOptions.type == 'commission') {
      this.formFields.push(
        this.fb.group({
          amount: '',
          date: '',
          memo: '',
          recId: ''
        })
      );
    } else {
      this.formFields.push(
        this.fb.group({
          stock: '',
          amount: '',
          date: '',
          memo: '',
          recId: ''
        })
      );
    }
  }

  deleteIDMSField(index) {
    this.formFields.removeAt(index);
  }

  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETCOMMISSIONS,
      '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year + '&type=' + this.filterOptions.type)
      .subscribe(
        res => {
          if (res.Status === 200) {
            this.commissionRecords = res.Data;

            this.initTable();
          } else this.commissionRecords = [];
        },
        err => console.log('error in getting commission records ', err)
      );
  }

  getInfo(row) {
    if (this.filterOptions.type == 'backend_commission') {
      this.getStockList(row.sales_person);
    }
    this.currentId = row.id;
    this.totalCommission = row.commission_amount;
    this.salesPersonName = row.name;

    this.initForm();

    this.newFieldsForm.patchValue({
      id: this.currentId
    });

    if (this.filterOptions.type == 'commission') {

      this.apiSvc.getService(this.constantSvc.APIConfig.GETCOMMISSIONHISTORY, '?id=' + this.currentId)
        .subscribe(
          res => {
            if (res.Status === 200) {
              res.Data.forEach(item => {
                const dateIS = item.pay_date.split('-');
                const y = Number(dateIS[0]);
                const m = Number(dateIS[1]);
                const d = Number(dateIS[2]);

                this.formFields.push(this.fb.group(
                  {
                    amount: item.amount,
                    memo: item.memo,
                    date: { year: y, month: m, day: d },
                    recId: item.id
                  }
                ));
              });
            } else this.addFormField();
          },
          err => console.log('error in gettting commission history')
        );
    } else {
      this.apiSvc.getService(this.constantSvc.APIConfig.GETBACKENDCOMMISSIONHISTORY, '?id=' + this.currentId)
        .subscribe(
          res => {
            if (res.Status === 200) {
              res.Data.forEach(item => {
                const dateIS = item.pay_date.split('-');
                const y = Number(dateIS[0]);
                const m = Number(dateIS[1]);
                const d = Number(dateIS[2]);

                this.formFields.push(this.fb.group(

                  {
                    stock:item.stock,
                    amount: item.amount,
                    memo: item.memo,
                    date: { year: y, month: m, day: d },
                    recId: item.id
                  }
                ));
              });
            } else this.addFormField();
          },
          err => console.log('error in gettting commission history')
        );
    }
  }

  onSubmit() {
    // console.log("form data", this.newFieldsForm.value)
    if (this.filterOptions.type == 'commission') {

      this.apiSvc.postService(this.constantSvc.APIConfig.ADDCOMMISSION, this.newFieldsForm.value)
        .subscribe(
          res => {
            if (res.Status === 201) {
              if ($.fn.DataTable.isDataTable('#usersTable')) {
                this.destroyTable();
              }

              this.loadData();

              $('#addModel').modal('hide');
            }
          },
          err => console.log('err ', err)
        );
    } else {
      this.apiSvc.postService(this.constantSvc.APIConfig.ADDBACKENDCOMMISSION, this.newFieldsForm.value)
        .subscribe(
          res => {
            if (res.Status === 201) {
              if ($.fn.DataTable.isDataTable('#usersTable')) {
                this.destroyTable();
              }

              this.loadData();

              $('#addModel').modal('hide');
            }
          },
          err => console.log('err ', err)
        );
    }
  }

  searchFilter(val) {
    const value = val ? val.trim() : '';
    this.oTable.search(value).draw();
  }

  initTable() {
    $(() => {
      this.oTable = $('#usersTable').DataTable({
        'paging': true,
        'lengthChange': false,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'processing': true,
        'pageLength': 5
      });
    });
  }

  destroyTable() {
    this.oTable.clear().destroy();
  }

  applyFilter() {
    if ($.fn.DataTable.isDataTable('#usersTable')) {
      this.destroyTable();
    }

    this.loadData();
  }

  exportFile(): void {
    let history;
    const todayDate = moment().format('MM/DD/YYYY');
    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTCOMMISSIONHISTORY, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year + '&type=' + this.filterOptions.type).subscribe(
      res => {
        if (res.Status === 200) {
          history = res.Data;

          const collection: any[] = [];
          const exprtcsv: any[] = [];

          const columns: any[] = ExportCommissionColumns.getColumns();

          (<any[]>JSON.parse(JSON.stringify(history))).forEach(rec => {
            let rowCount = 1;
            rec.history.forEach(element => {
              const row = new Object();
              for (let i = 0; i < columns.length; i++) {
                const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);
                row[columns[0].display] = rec.vendor_id;
                row[columns[1].display] = todayDate;
                row[columns[2].display] = todayDate;
                row[columns[3].display] = rowCount;
                row[columns[4].display] = element.memo;
                row[columns[5].display] = '260000';
                row[columns[6].display] = '100';
                row[columns[7].display] = '-' + element.amount;
                row[columns[8].display] = '';
              }

              ++rowCount;

              exprtcsv.push(row);
            });
          });

          ExportUtility.downloadcsv(exprtcsv, 'commissions-report_');
        } else {
          console.log('there are something wrong in get History ');
        }
      }, err => console.log('error in export commission history ', err)
    );
  }

  exportBackendUserFile(): void {
    let history;
    
    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTBACKENDUSERCOMMISSIONHISTORY, '?id='+ this.currentId).subscribe(
      res => {
        if (res.Status === 200) {
          history = res.Data;
          console.log("history");
          console.log(history);
          const collection: any[] = [];
          const exprtcsv: any[] = [];

          const columns: any[] = ExportBackendUserCommissionColumns.getColumns();

          (<any[]>JSON.parse(JSON.stringify(history))).forEach(rec => {
            let rowCount = 1;
              const row = new Object();
              for (let i = 0; i < columns.length; i++) {
                const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);
                row[columns[0].display] = rec.id;
                row[columns[1].display] = rec.commission_id;
                row[columns[2].display] = rec.stock;
                row[columns[3].display] = rec.amount;
                row[columns[4].display] = rec.pay_date;
                row[columns[5].display] = rec.memo;
              }

              ++rowCount;

              exprtcsv.push(row);
            });
          

          ExportUtility.downloadcsv(exprtcsv, 'commissions-report_backend_user');
        } else {
          console.log('there are something wrong in get History ');
        }
      }, err => console.log('error in export commission history ', err)
    );
  }
  exportSalesUserFile(): void {
    let history;
    
    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTSALESUSERCOMMISSIONHISTORY, '?id='+ this.currentId).subscribe(
      res => {
        if (res.Status === 200) {
          history = res.Data;
          console.log("history");
          console.log(history);
          const collection: any[] = [];
          const exprtcsv: any[] = [];

          const columns: any[] = ExportSalesUserCommissionColumns.getColumns();

          (<any[]>JSON.parse(JSON.stringify(history))).forEach(rec => {
            let rowCount = 1;
              const row = new Object();
              for (let i = 0; i < columns.length; i++) {
                const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);
                row[columns[0].display] = rec.id;
                row[columns[1].display] = rec.commission_id;
                row[columns[2].display] = rec.amount;
                row[columns[3].display] = rec.pay_date;
                row[columns[4].display] = rec.memo;
              }

              ++rowCount;

              exprtcsv.push(row);
            });
          

          ExportUtility.downloadcsv(exprtcsv, 'commissions-report_sales_user');
        } else {
          console.log('there are something wrong in get History ');
        }
      }, err => console.log('error in export commission history ', err)
    );
  }

  exportFileBackendCommission(): void {
    let records;
    const todayDate = moment().format('MM/DD/YYYY');
    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTBACKENDCOMMISSIONREPORT, '')
      .subscribe(
        res => {
          if (res.Status === 200) {
            records = res.Data;
            // console.log("RES", records);
            const collection: any[] = [];
            const columns: any[] = ExportCommissionColumns.getColumns();
            const exprtcsv: any[] = [];
            let rowCount = 1;
            let vID = '';
            let count = 1;
            (<any[]>JSON.parse(JSON.stringify(records))).forEach(rec => {
              if (rec.vendorId === vID) {
                count++;
              } else {
                count = 1;
              }
              vID = rec.vendorId;
              const row = new Object();
              for (let i = 0; i < columns.length; i++) {
                const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);
                row[columns[0].display] = rec.vendorId;
                row[columns[1].display] = todayDate;
                row[columns[2].display] = todayDate;
                row[columns[3].display] = count;
                row[columns[4].display] = 'Commissions';
                // row[columns[5].display] = '260000';
                row[columns[5].display] = '600005';
                // row[columns[6].display] = '100';
                row[columns[6].display] = rec.location;
                row[columns[7].display] = rec.commissionAmount;
                row[columns[8].display] = rec.stockNumber;
                // row[columns[9].display] = rec.contractdate;
              }
              ++rowCount;
              exprtcsv.push(row);
            });
            ExportUtility.downloadcsv(exprtcsv, 'commissions-backend-report_');
          } else if (res.Status === 404) {
            this.alertSvc.info("No records found to Export");
          } else {
            console.log('there are something wrong in get History ');
          }
        }, err => console.log('error in export commission history ', err)
      );
  }

  getStockList(userid){
    this.apiSvc.getService(this.constantSvc.APIConfig.GETSTOCKLIST, '?id='+userid)
    .subscribe(
      res => {
        if (res.Status === 200) {
          this.stockList = res.Data;
        }
      }, err => console.log('error in getting history ', err)
      );
  }

}
