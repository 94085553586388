import 'core-js/shim';
import { Component, OnInit, OnDestroy, NgZone} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HelperService, ConstantService, ApiService, AuthService } from '@app/core';

am4core.useTheme(am4themes_animated);
import * as moment from 'moment';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styles: [
    `#saleschartdiv {
      font-size: 15px;
    }
    `
  ]
})
export class PieChartComponent implements OnInit, OnDestroy {
  // @Input() data: any;
  private chart: am4charts.PieChart;
  public chartData: any = [];
  public currentMonth: string;
  public currentYear: string;
  userIDMSID: any;
  role: any;
  isIndividualSale = true;
  years: any = [];
  months: any = [];

  filterOptions = {
    month: '',
    year: '',
    department: ''
  };
  
  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private authenticationService: AuthService,
    private zone: NgZone
    ) { }

  ngOnInit() {
    const currentUser  = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.userIDMSID = currentUser.userData.idms_id;
      this.role = currentUser.userData.role_id;
      if (this.role === '1') {
        this.isIndividualSale = false;
     
      } else {
        
      }
    }
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;
    this.filterOptions.department = 'Sales';

    this.initChart();
    this.loadData();
  }
  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETSALESBYTEAMMEMBERS, '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year + '&department=' + this.filterOptions.department+ '&userId=' + this.userIDMSID + '&isIndividual=' + this.isIndividualSale).subscribe(
      res => {
        if (res.Status === 200) {
          this.chartData = res.Data;
          this.initChart();
        } else {
          this.chartData = [];
          this.initChart();
        }
      }, err => console.log('error in getting sales by team member')
    );
  }
  initChart() {
    this.zone.runOutsideAngular(() => {
      const chart = am4core.create('saleschartdiv', am4charts.PieChart);
      chart.radius = 100;

      // Add data
      chart.data = this.chartData;

      // Add and configure Series
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'sales';
      pieSeries.dataFields.category = 'name';
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.tooltipText = '{name}: Total Sales: ({sales})';
      pieSeries.labels.template.text = '{name}';

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

    });
  }

  applyFilter() {
    this.loadData();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
