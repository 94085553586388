import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ApiService, HelperService, ConstantService } from '@app/core';
import { MatDatepickerInputEvent } from '@angular/material';
declare var $: any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-deals-issues',
  templateUrl: './deals-issues.component.html',
  styleUrls: ['./deals-issues.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class DealsIssuesComponent implements OnInit, OnDestroy {
  private dataUrl = 'sales/getDealsErrors';
  public modalRef: BsModalRef;
  public config: { animated: true };
  public oTable: any;
  public totalErrors = 0;
  public listData = [];

  filterOptions = { startDate: '', endDate: '' };

  public userId = '';
  public roleId = '';
  public isIndividualSales = false;

  constructor(
    private apiService: ApiService,
    private modalService: BsModalService,
    private datepipe: DatePipe,
    private helperService: HelperService,
    private constantService: ConstantService
  ) { }

  ngOnInit() {
    const currentUser = this.helperService.lsGetItem(this.constantService.tokenConfig.CURRENT_USER);
    if (currentUser) {

      const roleId = currentUser.userData.role_id;
  
      if (roleId === '4' || roleId === '5') {
        this.isIndividualSales = true;
        this.userId = currentUser.userData.idms_id;
        this.roleId = roleId;
      }
    }

    this.initStartDate();
    this.loadData();
  }

  initStartDate() {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    this.filterOptions.startDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');
    this.filterOptions.endDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');

  }

  loadData() {
    this.apiService.getService(
      this.dataUrl, '?userId=' + this.userId + '&roleId=' + this.roleId + '&isIndividualSales=' +
      this.isIndividualSales + '&startDate=' +
      this.filterOptions.startDate + '&endDate=' + this.filterOptions.endDate).subscribe(
        res => {
          if (res.Status === 200) {
            this.totalErrors = res.TotalRecords;
            this.listData = res.Data;

            this.initTable();

          } else if (res.Status !== 200) {
            this.totalErrors = 0;
            this.listData = [];
          }
        },
        err => console.log(err)
      );
  }

  initTable() {
    if ($.fn.DataTable.isDataTable('#issuesTable')) {
      this.destroyTable();
    }

    setTimeout(() => {
      $(() => {
        this.oTable = $('#issuesTable').DataTable({
          'paging': true,
          'lengthChange': false,
          // 'searching': false,
          'ordering': true,
          'info': true,
          'autoWidth': false,
          'processing': true,
          'pageLength': 10,
          'dom': 'lrtip'
        });
      });
    }, 1);
  }

  searchDatatable(val) {
    this.oTable.search(val).draw();
  }

  destroyTable() {
    this.oTable.destroy();
  }

  applyFilter(type: string, event: MatDatepickerInputEvent<Date>) {

    this.filterOptions.startDate = this.datepipe.transform(this.filterOptions.startDate, 'yyyy-MM-dd');
    this.filterOptions.endDate = this.datepipe.transform(this.filterOptions.endDate, 'yyyy-MM-dd');

    this.loadData();
  }

  openModal(template: TemplateRef<any>) {
    // set initial date
    this.initStartDate();

    this.loadData();

    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

}
