import { Routes } from '@angular/router';
export const CONTENT_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './modules/home/home.module#HomeModule'
  },
  {
    path: 'users',
    loadChildren: './modules/users/users.module#UsersModule',
  },
  {
    path: 'sales',
    loadChildren: './modules/sales/sales.module#SalesModule'
  },
  {
    path: 'deals',
    loadChildren: './modules/deals/deals.module#DealsModule'
  },
  {
    path: 'tiers',
    loadChildren: './modules/tiers/tiers.module#TiersModule'
  },
  {
    path: 'rules',
    loadChildren: './modules/rules/rules.module#RulesModule'
  },
  {
    path: 'mr-fee',
    loadChildren: './modules/mr-fee/mr-fee.module#MrFeeModule'
  },
  {
    path: 'autoprice-request',
    loadChildren: './modules/autoprice-request/autoprice-request.module#AutopriceRequestModule'
  },
  {
    path: 'formulas',
    loadChildren: './modules/formulas/formulas.module#FormulasModule'
  },
  {
    path: 'settings',
    loadChildren: './modules/settings/settings.module#SettingsModule'
  },
  {
    path: 'vehicles',
    loadChildren: './modules/vehicles/vehicles.module#VehiclesModule'
  },
  {
    path: 'inspections',
    loadChildren: './modules/inspection-report/inspection-report.module#InspectionReportModule'
  },
];
