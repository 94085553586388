import { Component, OnInit } from '@angular/core';
import { ApiService, ConstantService } from '@app/core';

@Component({
  selector: 'app-sales-persons-table',
  templateUrl: './sales-persons-table.component.html',
})
export class SalesPersonsTableComponent implements OnInit {

  public salesmanOfMonth = Array();
  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
  ) { }

  ngOnInit() {
    this.getSalesmanOfMonth();
  }

  getSalesmanOfMonth(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.SALESMANOFMONTH, '').subscribe(
      data => {
        if (data.Status === 200) {
          this.salesmanOfMonth = data.Data;
        }
      }, error => { }
    );
  }

}
