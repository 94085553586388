import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConstantService, ApiService, HelperService } from '@app/core';
import { ExportCommissionColumns, ExportUtility } from '../../utility';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-buyers-commission-table',
  templateUrl: './buyers-commission-table.component.html',
  styleUrls: ['./buyers-commission-table.component.scss'],
})
export class BuyersCommissionTableComponent implements OnInit, AfterViewInit {
  newFieldsForm: FormGroup;
  currentMonth: string;
  currentYear: string;
  years: any = [];
  months: any = [];
  filterOptions = { month: '', year: '' };
  fields: Array<any> = [];
  currentId: any;
  totalCommission: any;
  buyerName: any;
  commissionRecords: any = [];
  oTable: any;

  constructor(
    private fb: FormBuilder,
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService
  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.months = this.helperSvc.getMonths();
    this.currentYear = (moment().year().toString());
    this.currentMonth = ('0' + (moment().month() + 1)).slice(-2).toString();
    this.filterOptions.year = this.currentYear;
    this.filterOptions.month = this.currentMonth;

    /* Initiate the form structure */
    this.initForm();
  }

  ngAfterViewInit() {
    this.loadData();
  }

  initForm() {
    this.newFieldsForm = this.fb.group({
      form_fields: this.fb.array([]),
      id: ''
    });
  }

  get formFields() {
    return this.newFieldsForm.get('form_fields') as FormArray;
  }

  addFormField() {
    this.formFields.push(this.fb.group({ amount: '', date: '', memo: '', recId: '' }));
  }

  deleteIDMSField(index) {
    this.formFields.removeAt(index);
  }


  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.GETBUYERSCOMMISSIONS,
      '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year)
      .subscribe(
        res => {
          if (res.Status === 200) {
            this.commissionRecords = res.Data;
            this.initTable();
          } else
            this.commissionRecords = [];
        },
        err => console.log('error in getting commission records ', err)
      );
  }

  getInfo(row) {
    this.currentId = row.id;

    this.totalCommission = row.commission_amount;

    this.buyerName = row.name;

    this.initForm();

    this.newFieldsForm.patchValue({
      id: this.currentId
    });

    this.apiSvc.getService(this.constantSvc.APIConfig.GETBUYERSCOMMISSIONHISTORY,
      '?id=' + this.currentId)
      .subscribe(
        res => {
          if (res.Status === 200) {
            res.Data.forEach(item => {
              const dateIS = item.pay_date.split('-');
              const y = Number(dateIS[0]);
              const m = Number(dateIS[1]);
              const d = Number(dateIS[2]);

              this.formFields.push(this.fb.group({
                amount: item.amount,
                memo: item.memo,
                date: { year: y, month: m, day: d },
                recId: item.id
              }
              ));
            });
          } else this.addFormField();
        },
        err => console.log('error in gettting commission history')
      );
  }

  onSubmit() {
    this.apiSvc.postService(this.constantSvc.APIConfig.ADDBUYERCOMMISSION, this.newFieldsForm.value)
      .subscribe(
        res => {
          if (res.Status === 201) {
            if ($.fn.DataTable.isDataTable('#buyersCommissionTable'))
              this.destroyTable();

            this.loadData();

            $('#addCommModal').modal('hide');
          }
        },
        err => console.log('err ', err)
      );
  }

  searchFilter(val) {
    const value = val ? val.trim() : '';
    this.oTable.search(value).draw();
  }

  initTable() {
    $(() => {
      this.oTable = $('#buyersCommissionTable').DataTable({
        'paging': true,
        'lengthChange': false,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'processing': true,
        'pageLength': 5,
      });
    });
  }

  destroyTable() {
    this.oTable.clear().destroy();
  }

  applyFilter() {
    if ($.fn.DataTable.isDataTable('#buyersCommissionTable')) {
      this.destroyTable();
    }
    this.loadData();
  }

  exportFile(): void {
    let history;

    const todayDate = moment().format('MM/DD/YYYY');

    this.apiSvc.getService(this.constantSvc.APIConfig.EXPORTBUYERSCOMMISSION,
      '?month=' + this.filterOptions.month + '&year=' + this.filterOptions.year)
      .subscribe(
        res => {
          if (res.Status === 200) {
            history = res.Data;

            const collection: any[] = [];

            const columns: any[] = ExportCommissionColumns.getColumns();

            const exprtcsv: any[] = [];

            (<any[]>JSON.parse(JSON.stringify(history))).forEach(rec => {
              let rowCount = 1;

              rec.history.forEach(element => {

                const row = new Object();

                for (let i = 0; i < columns.length; i++) {
                  const transfrmValue = ExportUtility.transform(rec[columns[0].variable], columns[0].filter);

                  row[columns[0].display] = rec.vendor_id;
                  row[columns[1].display] = todayDate;
                  row[columns[2].display] = todayDate;
                  row[columns[3].display] = rowCount;
                  row[columns[4].display] = 'Commission';
                  row[columns[5].display] = '650762';
                  row[columns[6].display] = '100';
                  row[columns[7].display] = '-' + element.amount;
                  row[columns[8].display] = '';
                }

                ++rowCount;
                exprtcsv.push(row);
              });
            });

            ExportUtility.downloadcsv(exprtcsv, 'buyers-commission-report_');
          } else {
            console.log('there are something wrong in get History ');
          }
        },
        err => console.log('error in export commission history ', err)
      );
  }

}
