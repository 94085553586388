import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first, delay } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, ConstantService, HelperService, AlertService } from '@app/core';
declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  returnUrl: string;
  error: string;
  isLoading: boolean;
  hide = true;
  departmentType = 'Sales Department';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private authenticationService: AuthService,
    private _cookieService: CookieService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    document.body.className = 'hold-transition login-page';

    $(() => {
      $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%'
      });
    });

    // get return url from route parameters or default to '/dashboard'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    this.buildForm();
  }

  get f() {
    return this.loginForm.controls;
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    const credentials = this.loginForm.value;
    this.authenticationService.login(this.constantSvc.APIConfig.LOGIN, credentials)
      .pipe(
        first(),
        delay(2000),
      ).subscribe(
        data => {
          this.isLoading = false;
          if (data.message) {
            const dashboardURL = this.constantSvc.dashboardConfig.DASHBOARD_URL;

            const currentUser = this.helperSvc.lsGetItem(this.constantSvc.tokenConfig.CURRENT_USER);
            const currentUserRole = currentUser.userData.role_id;

            if (currentUserRole === '1') {
              this.helperSvc.lsSetItem(dashboardURL, 'admin');
            } else if (currentUserRole === '2' || currentUserRole === '3') {
              this.helperSvc.lsSetItem(dashboardURL, 'manager');
            } else if (currentUserRole === '4' || currentUserRole === '5') {
              this.helperSvc.lsSetItem(dashboardURL, 'sales-person');
            } else if (currentUserRole === '6') {
              this.helperSvc.lsSetItem(dashboardURL, 'buyer');
            } else if (currentUserRole === '7') {
              this.helperSvc.lsSetItem(dashboardURL, 'inspector');
            }

            this._cookieService.delete('UserToken');
            this._cookieService.set('UserToken', currentUser.token, new Date(Date.now() + (30 * 60 * 1000))); // cookie set for 30 minutes
            this._cookieService.set('lastLogin', 'login');

            this.router.navigate([this.returnUrl]);
          } else if (data.error) {
            this.alertService.error(data.error ? data.error : 'Something went wrong please try again');
          }
        },
        error => this.alertService.error(error)
      );
  }

  changeDepartment(val) {
    const value = val.trim();
    this.departmentType = (value === 'sales') ? 'Sales Department' : 'Buyers Department';
    this.loginForm.patchValue({
      department: this.departmentType
    });
  }


  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      loginkey: ['', Validators.required],
      password: ['', Validators.required],
      department: this.departmentType,
    });
  }

  ngOnDestroy() {
    document.body.className = '';
  }

}
