import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule, HasRoleDirective } from '@app/core';
import { SharedModule } from '@app/shared';

import { AuthModule } from './modules/auth/auth.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { TopnavbarComponent } from './layouts/topnavbar/topnavbar.component';
import { AsidenavbarComponent } from './layouts/asidenavbar/asidenavbar.component';
import { FooternavbarComponent } from './layouts/footernavbar/footernavbar.component';
import { AdminMenuComponent } from './layouts/asidenavbar/admin/admin-menu.component';
import { ManagerMenuComponent } from './layouts/asidenavbar/manager/manager-menu.component';
import { SalesPersonMenuComponent } from './layouts/asidenavbar/sales-person/sales-person-menu.component';
import { PageNotFoundComponent } from './modules/auth/pages/page-not-found/page-not-found.component';
import { BuyerMenuComponent } from './layouts/asidenavbar/buyer-menu/buyer-menu.component';
import { InspectorMenuComponent } from './layouts/asidenavbar/inspector-menu/inspector-menu.component';
import { DealsIssuesComponent } from './layouts/topnavbar/deals-issues/deals-issues.component';
import { CashAdvanceComponent } from './layouts/topnavbar/cash-advance/cash-advance.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    ContentLayoutComponent,
    TopnavbarComponent,
    FooternavbarComponent,
    AsidenavbarComponent,
    AdminMenuComponent,
    ManagerMenuComponent,
    SalesPersonMenuComponent,
    PageNotFoundComponent,
    HasRoleDirective,
    BuyerMenuComponent,
    InspectorMenuComponent,
    DealsIssuesComponent,
    CashAdvanceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthModule,
    ModalModule.forRoot()
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
