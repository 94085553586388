import { Component, OnInit } from '@angular/core';
import { ApiService, ConstantService } from '@app/core';

@Component({
  selector: 'app-buyer-of-month-table',
  templateUrl: './buyer-of-month-table.component.html',
  styles: []
})
export class BuyerOfMonthTableComponent implements OnInit {

  buyerOfMonth: any = [];
  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(): void {
    this.apiSvc.getService(this.constantSvc.APIConfig.BUYEROFMONTH, '').subscribe(
      data => {
        if (data.Status === 200) {
          this.buyerOfMonth = data.Data;
        }
      }, error => { }
    );
  }

}
