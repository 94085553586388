import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-inspector-menu',
    templateUrl: './inspector-menu.component.html'
})
export class InspectorMenuComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
