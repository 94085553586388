import 'core-js/shim';
import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ApiService, ConstantService, HelperService } from '@app/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as moment from 'moment';

@Component({
  selector: 'app-monthly-sales-graph',
  templateUrl: './monthly-sales-graph.component.html',
  styles: []
})
export class MonthlySalesGraphComponent implements OnInit, OnDestroy {
  private chart: am4charts.PieChart;

  chartData: any = [];
  currentYear: string;
  years: any = [];
  filterOptions = {
    year: ''
  };

  constructor(
    private apiSvc: ApiService,
    private constantSvc: ConstantService,
    private helperSvc: HelperService,
    private zone: NgZone
  ) { }

  ngOnInit() {
    this.years = this.helperSvc.getYears();
    this.currentYear = (moment().year().toString());
    this.filterOptions.year = this.currentYear;

    this.initGraph();
    this.loadData();
  }

  loadData() {
    this.apiSvc.getService(this.constantSvc.APIConfig.COUNTMONTHLYSALES, '?year=' + this.filterOptions.year).subscribe(
      res => {
        if (res.Status === 200) {
          this.chartData = res.Data;
          this.initGraph();
        }
      }, err => console.log('err in count monthly sales ', err)
    );
  }

  initGraph() {
    this.zone.runOutsideAngular(() => {
      // Create chart instance
      const chart = am4core.create('ChartDiv', am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();

      // Add data
      chart.data = this.chartData;

      // Create axes
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'month';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      categoryAxis.renderer.labels.template.horizontalCenter = 'right';
      categoryAxis.renderer.labels.template.verticalCenter = 'middle';
      categoryAxis.renderer.labels.template.rotation = 300;
      categoryAxis.tooltip.disabled = true;
      // categoryAxis.renderer.minHeight = 100;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 10;
      valueAxis.renderer.minGridDistance = 40;
      valueAxis.min = 0;

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'salesCount';
      series.dataFields.categoryX = 'month';
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;

      series.tooltip.pointerOrientation = 'vertical';

      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;

      // on hover, make corner radiuses bigger
      const hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // Cursor
      chart.cursor = new am4charts.XYCursor();

    });
  }

  applyFilter() {
    this.loadData();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
