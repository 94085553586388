import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footernavbar',
  templateUrl: './footernavbar.component.html',
  styleUrls: ['./footernavbar.component.scss']
})
export class FooternavbarComponent implements OnInit {
  currentYear = (new Date()).getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
