export class ExportCommissionColumns {
  public static getColumns(): any {
    return [
      {
        display: 'VENDOR_ID',
        variable: 'vendor_id',
        filter: 'text'
      },
      {
        display: 'CREATED_DATE',
        variable: 'todayDate',
        filter: 'date'
      },
      {
        display: 'DUE_DATE',
        variable: 'todayDate',
        filter: 'date'
      },
      {
        display: 'LINE_NO',
        variable: 'sales_manager',
        filter: 'text'
      },
      {
        display: 'MEMO',
        variable: 'memo',
        filter: 'text'
      },
      {
        display: 'ACCT_NO',
        variable: 'acct_no',
        filter: 'text'
      },
      {
        display: 'LOCATION_ID',
        variable: 'location_id',
        filter: 'text'
      },
      {
        display: 'AMOUNT',
        variable: 'amount',
        filter: 'text'
      },
      {
        display: 'APBILLITEM_PROJECTID',
        variable: 'APBILLITEM_PROJECTID',
        filter: 'text'
      }

    ];
  }
}

